import React, {useEffect} from 'react';
import {useAppSelector} from "shared/lib/hooks/hooks";
import axios from "axios";
import dayjs from "dayjs";
import {serverURL} from "shared/lib/serverURL";
import calendar from 'assets/images/admin/calendar_blue.svg';
import location from 'assets/images/admin/location.svg';
import word from 'assets/images/marathon/word.svg';
import styles from './MarathonInfo.module.scss';

type Props = {
  language: string,
  setIsLanguageDropDownVisible: (isVisible: boolean) => void,
}

const MarathonInfo: React.FC<Props> = ({language, setIsLanguageDropDownVisible}) => {
  const marathonInfo = useAppSelector(state => state.marathon.marathonForAdmin);

  const handleDocumentDownload = async (documentUrl: any) => {
    const documentUrlCopy = {...documentUrl};

    const documentName = documentUrlCopy.document.slice(59);
    const documentExtension = documentUrlCopy.document.split('.')[documentUrlCopy.document.split('.').length - 1]
    const response = await axios.get(`${serverURL}${documentUrl.document}`, {responseType: 'blob'});
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${documentName}.${documentExtension}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  useEffect(() => {
    setIsLanguageDropDownVisible(true);

    return () => {
      setIsLanguageDropDownVisible(false);
    }
  }, [setIsLanguageDropDownVisible]);

  //TODO FIX IMPORTS EVERYWHERE ширину параграфа ограничить и скорее всего нужно отсортировать serialNumber
  //стоимость по периодом там тоже чёто сделай когда он не заполняет все поля и пытыается создать

  return (
    <>
      {marathonInfo ? (
        <div className={styles.marathonInfoContainer}>
          <div className={styles.marathonInfoTop}>
            <div className={styles.marathonInfoDate}>
              <img src={calendar} alt="Календарь"/>
              <p className={styles.marathonInfoDateText}>
                Дата проведения - {dayjs(marathonInfo.date).format('DD.MM.YYYY')}
              </p>
            </div>
            <div className={styles.marathonInfoLocation}>
              <img src={location} alt="Расположение"/>
              <p className={styles.marathonInfoLocationText}>
                Место проведения - {marathonInfo.translations[language].place}
              </p>
            </div>
          </div>

          <div className={styles.marathonDescription}>
            <h3 className={styles.marathonDescriptionTitle}>Описание</h3>

            <p dangerouslySetInnerHTML={{__html: marathonInfo.translations[language].text}}/>
          </div>

          <div className={styles.marathonDocuments}>
            <h3 className={styles.marathonDocumentsTitle}>Документы</h3>
            {marathonInfo.documents.map((document: any, index: number) => (
              <div key={index}>
                <div
                  onClick={() => handleDocumentDownload(document)}
                  className={styles.marathonDocumentsDocument}
                >
                  <img src={word} alt="документ"/>
                </div>
                <p
                  style={{cursor: 'pointer', marginBottom: '25px'}}
                  onClick={() => handleDocumentDownload(document)}
                >
                  {document.document.slice(59)}
                </p>
              </div>
            ))}
          </div>

          <div className={styles.marathonPartners}>
            <h3 className={styles.marathonPartnersTitle}>Партнёры</h3>
            <h4>
              {marathonInfo.partners.map((partner: any, index: number) => (
                <div key={index}>
                  <p className={styles.marathonPartnersName}>
                    {partner.translations[language].name}
                  </p>
                  {partner.partnerCompanies.map((partnerCompany: any, index: number) => (
                    <div className={styles.marathonPartnerCompaniesImgContainer} key={index}>
                      <a href={partner.partnerCompanies[index].url} rel="noreferrer" target="_blank">
                        <img
                          className={styles.marathonPartnerCompaniesImg}
                          src={`${serverURL}${partner.partnerCompanies[index].logo}`}
                          alt="Логотип партнера"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </h4>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MarathonInfo;
