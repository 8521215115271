import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from '../api/typedApi';
import { toast } from "react-toastify";

const showToast = (message: string, type: 'success' | 'error') => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const deleteSubVoucher = createAsyncThunk(
  'user/deleteSubVoucher',
  async (subVoucherId: number, { rejectWithValue }) => {
    try {
      const response = await typedAdminAPI.deleteSubVoucher(subVoucherId);
      showToast('Субваучер успешно удален', 'success');
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail;
      const errorStatus = error.response?.data?.status;

      if (errorStatus === 400 && errorMessage === "Вы не можете удалить субваучер c забронирвоанными промокодами.") {
        showToast(errorMessage, 'error');
        return rejectWithValue(errorMessage);
      }

      if (errorStatus === 404) {
        showToast('Субваучер не найден', 'error');
        return rejectWithValue('Субваучер не найден');
      }

      showToast('Ой, что-то пошло не так, попробуйте обновить страницу', 'error');
      return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
    }
  }
);