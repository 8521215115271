import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from '../api/typedApi';

export const getCorporateOraganizationsInfoBySearch = createAsyncThunk(
  'admin/getCoporateOraganizationsInfoBySearch', async (participantInfo: any, { rejectWithValue }) => {
    try {
      const response = await typedAdminAPI.getCorporateOrganizationsInfoBySearch(participantInfo);
      return response.data;
    } catch (error) {
      //Don't need to handle error
      return rejectWithValue('Error');
    }
  }
);
