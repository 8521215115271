import { Box } from "@mui/material";
import style from "./load-file.module.scss";
import { Editor } from "react-draft-wysiwyg";
import linkImg from "assets/images/admin/link.svg";
import underlineImg from "assets/images/admin/text-underline.svg";
import boldImg from "assets/images/admin/text-bold.svg";
import italicImg from "assets/images/admin/text-italic.svg";
import centerImg from "assets/images/admin/textalign-center.svg";
import leftImg from "assets/images/admin/textalign-left.svg";
import rightImg from "assets/images/admin/textalign-right.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./textEditor.css";
import { useFormContext } from "react-hook-form";

export const TextAreaDescription = ({
  placeholder,
  value,
  handleChange,
  indexLanguage,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const isDescriptionError = errors?.translations?.[indexLanguage]?.text;
  return (
    <Box>
      <label className={`label-adminPanel ${style.section_label}`}>
        Описание
        <Editor
          placeholder={placeholder}
          // @ts-ignore
          editorState={value}
          editorStyle={{ border: isDescriptionError ? "1px solid red" : "" }}
          toolbarClassName="textEditor-toolbar"
          wrapperClassName="demo-wrapper"
          editorClassName="textEditor-text"
          // @ts-ignore
          onEditorStateChange={handleChange}
          toolbar={{
            options: ["blockType", "inline", "list", "textAlign", "link"],
            inline: {
              options: ["bold", "italic", "underline"],
              bold: { icon: boldImg },
              italic: { icon: italicImg },
              underline: { icon: underlineImg },
            },
            blockType: {
              inDropdown: false,
              options: ["H2"],
              className: "textEditor-h2",
            },
            list: {
              inDropdown: false,
              options: ["unordered", "ordered"],
              className: "textEditor-list",
            },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["left", "center", "right"],
              left: { icon: leftImg },
              center: { icon: centerImg },
              right: { icon: rightImg },
            },
            link: {
              inDropdown: false,
              showOpenOptionOnHover: true,
              options: ["link", "unlink"],
              link: { icon: linkImg },
              unlink: { icon: linkImg },
            },
          }}
        />
      </label>
      <Box>
        <Box className="globalErrorMessage">{isDescriptionError?.message}</Box>
      </Box>
    </Box>
  );
};
