import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";
import axios from "axios";
import { IGetCorporateIssueByMarathonIdAndClientIdParams, IGetCorporateIssueByMarathonIdAndClientIdRes } from "../types/getCorporateIssueByMarathonIdAndClientId";

export const getCorporateIssueByMarathonIdAndClientId = createAsyncThunk<any, any>(
  "admin/getCorporateIssueByMarathonIdAndClientId",
  async (corporateUserList: any) => {
    try {
      const res = await typedAdminAPI.getCorporateIssueByMarathonIdAndClientId(corporateUserList)
      return res
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response?.data || error.message);
      }
      return Promise.reject("An unexpected error occurred");
    }
  }
);
