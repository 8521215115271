import { CloseFilter, ImportExcelFile, ShowFilter } from "assets/icons";
import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import {
  circularProgressWrapperStyles,
  importExcelFileButton,
  inputLabel,
  participantListTableCell,
  selectCategory,
  showFilterButton,
} from "entities/admin/lib/MUIStyles/MUIStyles";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "shared/lib/hooks/hooks";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axiosApi from "shared/api/axiosApi";
import { getMarathonResultsByAdmin } from "entities/admin/model/thunk/getMarathonResultsByAdmin";
import { getMarathonResultsBySearch } from "../../model/thunk/getMarathonResultsBySearch";
import searchIcon from "assets/images/admin/search-icon.svg";
import styles from "./marathonResults.module.scss";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ECF2F7",
    color: "#3E444A",
    padding: "8px",
    fontFamily: "Raleway",
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: "700 !important",
    "@media (max-width: 1100px)": {
      padding: "8px 32px",
      width: "150px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F5FAFF",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0090D1",
          },
        },
      },
    },
  },
});

const MarathonResults: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isGetMarathonResultsLoading = useAppSelector(
    (state) => state.marathon.isGetMarathonResultsLoading
  );
  const marathonResults = useAppSelector(
    (state) => state.marathon.marathonResults
  );
  const marathonResultsCount = useAppSelector(
    (state) => state.marathon.marathonResultsCount
  );
  const marathonResultsDistances = useAppSelector(
    (state) => state.marathon.marathonResultsDistances
  );
  const isGetMarathonResultsBySearchLoading = useAppSelector(
    (state) => state.marathon.isGetMarathonResultsBySearchLoading
  );

  const hiddenFileInput = React.useRef<null | HTMLInputElement>(null);
  const [excelFile, setExcelFile] = useState(null);
  const [importFileLoading, setImportFileLoading] = useState<boolean>(false);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [participantSearch, setParticipantSearch] = useState<string>(
    sessionStorage.getItem("sortAllUser") || ""
  );
  const [distance, setDistance] = useState("");
  const [gender, setGender] = useState("");
  const debouncedValue = useDebounce<string>(participantSearch, 800);
  const [distanceAges, setDistanceAges] = useState<any>([]);
  const [chosenDistanceAge, setChosenDistanceAge] = useState<string>("");

  const handleFileChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    setExcelFile(fileUploaded);
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleOnPageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
  };

  const handleParticipantSearch = (participant: string) => {
    console.log(participant);

    sessionStorage.setItem("sortAllUser", participant);
    setParticipantSearch(participant);
    setPage(1);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getMarathonResultsByAdmin({
          marathonId: +id,
          page,
          pageSize,
          gender,
          distance,
          distanceAgeId: chosenDistanceAge,
        })
      );
    }
  }, [dispatch, id, page, pageSize, distance, gender, chosenDistanceAge]);

  useEffect(() => {
    if (id) {
      dispatch(
        getMarathonResultsBySearch({
          marathonId: +id,
          participantSearch: debouncedValue,
        })
      );
    }
  }, [dispatch, debouncedValue, id]);

  useEffect(() => {
    const distanceAges = marathonResultsDistances.find(
      (marathonResultDistance) => marathonResultDistance.name === distance
    );

    if (distanceAges) {
      setDistanceAges(distanceAges.distanceAges);
    }

    if (distanceAges && gender) {
      const filteredDistanceAgesByGender = distanceAges.distanceAges.filter(
        (distanceAges: any) => {
          if (gender === "false") {
            return distanceAges.gender === false;
          }

          return distanceAges.gender === true;
        }
      );

      setChosenDistanceAge("");
      setDistanceAges(filteredDistanceAgesByGender);
    }
  }, [distance, marathonResultsDistances, gender]);

  const fetchData = async () => {
    setImportFileLoading(true);
    try {
      const response = await axiosApi.post(
        `/results/excel/${id}`,
        { excelFile },
        {
          headers: {
            "content-type": "multipart/form-data",
            "Accept-Language": "ru-ru",
          },
        }
      );

      if (response.data === 200) {
        setImportFileLoading(false);
        if (id) {
          dispatch(
            getMarathonResultsByAdmin({
              marathonId: +id,
              page: 1,
              pageSize,
              gender: "",
              distance: "",
              distanceAgeId: "",
            })
          );
        }
      }
    } catch (error: any) {
      // if (error.response.data.status === 400) {
      toast.error(error.response.data.title, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // }
    } finally {
      setImportFileLoading(false);
      setExcelFile(null);

      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    }
  };

  useEffect(() => {
    if (excelFile) {
      fetchData();
    }
  }, [excelFile]);

  return (
    <>
      <div className={styles.marathonResultsTop}>
        <h1 className={styles.marathonResultsTopMainTitle}>
          Результаты марафона
        </h1>
      </div>
      <div className={styles.marathonResultsContainer}>
        <div className={styles.marathonResultsInnerTop}>
          <h2 className={styles.marathonResultsSubTitle}>
            Список участников{" "}
            <span className={styles.marathonResultsCount}>
              {marathonResultsCount}
            </span>
          </h2>
          <Button
            startIcon={<ImportExcelFile />}
            disabled={importFileLoading}
            variant={importFileLoading ? "contained" : "text"}
            sx={{ ...importExcelFileButton, position: "relative" }}
            onClick={handleClick}
          >
            Импорт
            {importFileLoading && (
              <CircularProgress
                size={20}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
          <input
            type="file"
            accept={".xlsx, .xls, .csv"}
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <span className={styles.participantsFilterContainer}>
          <span className={styles.participantInputContainer}>
            <input
              className={styles.participantInput}
              placeholder="Поиск по имени, фамилии, стартовым номерам"
              type="text"
              value={participantSearch}
              onChange={(e) => handleParticipantSearch(e.target.value)}
            />
            <img
              className={styles.participantSearchIcon}
              src={searchIcon}
              alt="Поиск"
            />
          </span>
          <Button
            startIcon={isShowFilter ? <CloseFilter /> : <ShowFilter />}
            variant="contained"
            sx={showFilterButton}
            onClick={() => setIsShowFilter((prevState) => !prevState)}
          >
            {isShowFilter ? `${t("filters.hideFilters")}` : `${t("filters.showFilters")}`}
          </Button>
        </span>
        {isShowFilter ? (
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className={styles.filterDistance}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}
                >
                  <span className={styles.selectTitle}>Дистанция</span>
                  <button
                    className={styles.resetSelectedItem}
                    type="button"
                    onClick={() => {
                      setDistance("");
                      setChosenDistanceAge("");
                      setGender("");
                    }}
                  >
                    Сбросить
                  </button>
                </div>
                <FormControl fullWidth>
                  {distance ? null : (
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={inputLabel}
                      shrink={false}
                    >
                      Выберите дистанцию
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={distance}
                    onChange={(event) => setDistance(event.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          padding: "0 10px",
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#e3f2fd",
                          },
                        },
                      },
                    }}
                    sx={selectCategory}
                  >
                    {marathonResultsDistances.map((marathonResultDistance) => (
                      <MenuItem
                        key={marathonResultDistance.id}
                        value={marathonResultDistance.name}
                        sx={{ borderRadius: "10px" }}
                        className={styles.resetMuiDefaultStyle}
                      >
                        {marathonResultDistance.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.genderFilter}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}
                >
                  <span className={styles.selectTitle}>Пол</span>
                  <button
                    className={styles.resetSelectedItem}
                    type="button"
                    onClick={() => setGender("")}
                  >
                    Сбросить
                  </button>
                </div>
                <FormControl fullWidth>
                  {gender ? null : (
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={inputLabel}
                      shrink={false}
                    >
                      Выберите пол
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    onChange={(event) => setGender(event.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          padding: "0 10px",
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#e3f2fd",
                          },
                        },
                      },
                    }}
                    sx={selectCategory}
                  >
                    <MenuItem
                      value={"false"}
                      sx={{ borderRadius: "10px" }}
                      className={styles.resetMuiDefaultStyle}
                    >
                      Женский
                    </MenuItem>
                    <MenuItem
                      value={"true"}
                      sx={{ borderRadius: "10px" }}
                      className={styles.resetMuiDefaultStyle}
                    >
                      Мужской
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={styles.categoryFilter}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}
                >
                  <span className={styles.selectTitle}>Категория</span>
                  <button
                    className={styles.resetSelectedItem}
                    type="button"
                    onClick={() => setChosenDistanceAge("")}
                  >
                    Сбросить
                  </button>
                </div>
                <FormControl fullWidth disabled={distance === ""}>
                  {chosenDistanceAge ? null : (
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={inputLabel}
                      shrink={false}
                    >
                      Выберите категорию
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={chosenDistanceAge}
                    onChange={(event) =>
                      setChosenDistanceAge(event.target.value)
                    }
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          padding: "0 10px",
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#e3f2fd",
                          },
                        },
                      },
                    }}
                    sx={selectCategory}
                  >
                    <MenuItem
                      value={"null"}
                      sx={{ borderRadius: "10px" }}
                      className={styles.resetMuiDefaultStyle}
                    >
                      ЛОВЗ
                    </MenuItem>
                    {distanceAges.map((distanceAge: any) => (
                      <MenuItem
                        key={distanceAge.id}
                        value={distanceAge.id}
                        sx={{ borderRadius: "10px" }}
                        className={styles.resetMuiDefaultStyle}
                      >
                        {`${distanceAge.gender ? "Муж:" : "Жен:"} От ${distanceAge.ageFrom
                          } До ${distanceAge.ageTo}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Box>
          </ThemeProvider>
        ) : null}
        {isGetMarathonResultsLoading || isGetMarathonResultsBySearchLoading ? (
          <Box sx={circularProgressWrapperStyles}>
            <CircularProgress />
          </Box>
        ) : marathonResultsCount === 0 ? (
          <Box>
            <p className={styles.noRecords}>Нет записей</p>
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              maxHeight: "80vh",
              marginTop: "30px",
              borderRadius: "12px 12px 0 0",
            }}
          >
            <Table
              sx={{ minWidth: 700 }}
              aria-label="customized table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Имя
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Фамилия
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Пол
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Название марафона
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Дистанция
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Стартовый номер
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Категории
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Место среди всех
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Место в своей категории
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Начало
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    Личное время
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {marathonResults.map((marathonResult) => (
                  <StyledTableRow key={marathonResult.id}>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.user?.name}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.user?.surname}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.user?.gender ? "М" : "Ж"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.marathon?.name}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.application?.distance}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.application?.number}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult.application?.distanceAge || "ЛОВЗ"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {`${marathonResult?.generalPlace}/${marathonResult?.generalCount}`}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {`${marathonResult?.categoryPlace}/${marathonResult?.categoryCount}`}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult?.gunTime}
                      {/* {dayjs(marathonResult?.gunTime).format("DD.MM.YYYY")} */}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={participantListTableCell}
                    >
                      {marathonResult?.chipTime}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {marathonResultsCount === 0 || isGetMarathonResultsLoading ? null : (
          <Stack
            spacing={2}
            sx={{
              margin: "30px auto 20px auto",
              maxWidth: "500px",
              alignItems: "center",
            }}
          >
            <Pagination
              count={
                marathonResultsCount
                  ? Math.ceil(marathonResultsCount / pageSize)
                  : 1
              }
              page={page}
              onChange={handleOnPageChange}
              color="primary"
              size="medium"
              variant="outlined"
              shape="rounded"
              sx={{ margin: "0 auto" }}
            />
          </Stack>
        )}
      </div>
    </>
  );
};

export default MarathonResults;
