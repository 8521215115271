import React from "react";
import Box from "@mui/material/Box";
import { CloseFilter } from "assets/icons";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  inputLabel,
  participantsListAutoComplete,
  selectCategory,
  showFilterButton,
} from "../../lib/MUIStyles/MUIStyles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countriesArr } from "entities/viewer/lib/profileData";
import styles from "../ParticipantsList/ParticipantsList.module.scss";
import { useAppDispatch } from "shared/lib/hooks/hooks";

type Props = {
  distances: any;
  gender: string;
  setGender: (gender: any) => any;
  age: string;
  setAge: (age: any) => any;
  country: any;
  setCountry: (country: any) => any;
  setCountryIndex: (index: any) => any;
  payment: string;
  setPayment: (payment: any) => any;
  participantType: string;
  setParticipantType: (participantType: any) => any;
  startPackage: string;
  setStartPackage: (startPackage: any) => any;
  participantStatus: string;
  setParticipantStatus: (participantStatus: any) => any;
  specificDistance: any;
  setSpecificDistance: any;
  setPage: (page: any) => any;
};

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0090D1",
          },
        },
      },
    },
  },
});

const modifiedCountries = countriesArr.map((country, index) => ({
  label: country,
  index,
}));

const Filters: React.FC<Props> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const specificDistance = props.distances.find(
    (distance: any) => distance.id === +props.specificDistance.split(" ")[1]
  );
  const resetAllFilters = () => {
    dispatch(props.setSpecificDistance(""));
    dispatch(props.setGender(""));
    dispatch(props.setAge(""));
    dispatch(props.setCountry(null));
    dispatch(props.setPayment(""));
    dispatch(props.setParticipantType(""));
    dispatch(props.setStartPackage(""));
    dispatch(props.setParticipantStatus(""));
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: "24px", display: "flex", flexWrap: "wrap" }}>
        <Box
          sx={{
            marginRight: "12px",
            marginBottom: "20px",
            minWidth: "150px",
            width: "250px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
            <span className={styles.selectTitle}>Дистанция</span>
            <button
              className={styles.resetSelectedItem}
              type="button"
              onClick={() => {
                dispatch(props.setSpecificDistance(""));
              }}
            >
              Сбросить
            </button>
          </div>
          <FormControl fullWidth>
            {props.specificDistance ? null : (
              <InputLabel
                id="demo-simple-select-label"
                sx={inputLabel}
                shrink={false}
              >
                Выберите дистанцию
              </InputLabel>
            )}
            <Select
              value={props.specificDistance}
              onChange={(event) => {
                dispatch(props.setSpecificDistance(event.target.value));
                dispatch(props.setPage(1));
                dispatch(props.setAge(""));
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "12px",
                    padding: "0 10px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  },
                },
              }}
              sx={selectCategory}
            >
              {props.distances.map((distance: any) => {
                return (
                  <MenuItem
                    key={distance.id}
                    value={"distance " + distance.id + " " + distance.name}
                    sx={{ borderRadius: "10px" }}
                    className={styles.resetMuiDefaultStyle}
                  >
                    {distance.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ minWidth: "150px", marginRight: "12px", width: "250px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
            <span className={styles.selectTitle}>Пол</span>
            <button
              className={styles.resetSelectedItem}
              type="button"
              onClick={() => dispatch(props.setGender(""))}
            >
              Сбросить
            </button>
          </div>
          <FormControl fullWidth>
            {props.gender ? null : (
              <InputLabel
                id="demo-simple-select-label"
                sx={inputLabel}
                shrink={false}
              >
                Выберите пол
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.gender}
              onChange={(event) =>
                dispatch(props.setGender(event.target.value))
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "12px",
                    padding: "0 10px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  },
                },
              }}
              sx={selectCategory}
            >
              <MenuItem
                value={"false"}
                sx={{ borderRadius: "10px" }}
                className={styles.resetMuiDefaultStyle}
              >
                Женский
              </MenuItem>
              <MenuItem value={"true"} sx={{ borderRadius: "10px" }}>
                Мужской
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {
          <Box sx={{ minWidth: "150px", marginRight: "12px", width: "250px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <span className={styles.selectTitle}>Возраст</span>
              <button
                className={styles.resetSelectedItem}
                type="button"
                onClick={() => dispatch(props.setAge(""))}
              >
                Сбросить
              </button>
            </div>
            <FormControl fullWidth disabled={!Boolean(specificDistance)}>
              {props.age ? null : (
                <InputLabel
                  id="demo-simple-select-label"
                  sx={inputLabel}
                  shrink={false}
                >
                  Выберите возраст
                </InputLabel>
              )}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.age}
                onChange={(event) => {
                  dispatch(props.setPage(1));
                  dispatch(props.setAge(event.target.value));
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: "12px",
                      padding: "0 10px",
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#e3f2fd",
                      },
                    },
                  },
                }}
                sx={selectCategory}
              >
                {specificDistance?.distanceAges
                  .filter((distanceAge: any) =>
                    props.gender === "false"
                      ? !distanceAge.gender
                      : props.gender === "true"
                      ? distanceAge.gender
                      : true
                  )
                  .map((distance: any) => {
                    return (
                      <MenuItem
                        key={distance.id}
                        value={
                          distance.gender === true
                            ? "Муж От " +
                              distance.ageFrom +
                              " До " +
                              distance.ageTo +
                              " " +
                              distance.id
                            : " Жен От " +
                              distance.ageFrom +
                              " До " +
                              distance.ageTo +
                              " " +
                              distance.id
                        }
                        sx={{ borderRadius: "10px" }}
                        className={styles.resetMuiDefaultStyle}
                      >
                        {props.gender === "" && (
                          <span>
                            {distance.gender === true ? "Муж" : "Жен"} От{" "}
                            {distance.ageFrom} До {distance.ageTo}
                          </span>
                        )}
                        {props.gender !== "" && (
                          <span>
                            От {distance.ageFrom} До {distance.ageTo}
                          </span>
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        }
        <Box sx={{ minWidth: "150px", marginRight: "12px", width: "250px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
            <span className={styles.selectTitle}>Способ оплаты</span>
            <button
              className={styles.resetSelectedItem}
              type="button"
              onClick={() => dispatch(props.setPayment(""))}
            >
              Сбросить
            </button>
          </div>
          <FormControl fullWidth>
            {props.payment ? null : (
              <InputLabel
                id="demo-simple-select-label"
                sx={inputLabel}
                shrink={false}
              >
                Выберите способ
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.payment}
              name="age"
              onChange={(event) =>
                dispatch(props.setPayment(event.target.value))
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "12px",
                    padding: "0 10px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  },
                },
              }}
              sx={selectCategory}
            >
              <MenuItem
                value={"2"}
                sx={{ borderRadius: "10px" }}
                className={styles.resetMuiDefaultStyle}
              >
                Онлайн
              </MenuItem>
              <MenuItem value={"1"} sx={{ borderRadius: "10px" }}>
                Ваучер
              </MenuItem>
              <MenuItem value={"0"} sx={{ borderRadius: "10px" }}>
                Льготный участник
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: "150px", marginRight: "12px", width: "250px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
            <span className={styles.selectTitle}>Тип участника</span>
            <button
              className={styles.resetSelectedItem}
              type="button"
              onClick={() => dispatch(props.setParticipantType(""))}
            >
              Сбросить
            </button>
          </div>
          <FormControl fullWidth>
            {props.participantType ? null : (
              <InputLabel
                id="demo-simple-select-label"
                sx={inputLabel}
                shrink={false}
              >
                Выберите тип
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.participantType}
              name="age"
              onChange={(event) =>
                dispatch(props.setParticipantType(event.target.value))
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "12px",
                    padding: "0 10px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  },
                },
              }}
              sx={selectCategory}
            >
              <MenuItem
                value={"true"}
                sx={{ borderRadius: "10px" }}
                className={styles.resetMuiDefaultStyle}
              >
                ЛОВЗ
              </MenuItem>
              {/*<MenuItem value={''} sx={{borderRadius: '10px'}}>Все</MenuItem>*/}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: "150px", marginRight: "12px", width: "250px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
            <span className={styles.selectTitle}>Старт-пакет</span>
            <button
              className={styles.resetSelectedItem}
              type="button"
              onClick={() => dispatch(props.setStartPackage(""))}
            >
              Сбросить
            </button>
          </div>
          <FormControl fullWidth>
            {props.startPackage ? null : (
              <InputLabel
                id="demo-simple-select-label"
                sx={inputLabel}
                shrink={false}
              >
                Выберите статус
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.startPackage}
              name="age"
              onChange={(event) =>
                dispatch(props.setStartPackage(event.target.value))
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "12px",
                    padding: "0 10px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  },
                },
              }}
              sx={selectCategory}
            >
              <MenuItem
                value={"0"}
                sx={{ borderRadius: "10px" }}
                className={styles.resetMuiDefaultStyle}
              >
                Выдан
              </MenuItem>
              <MenuItem value={"1"} sx={{ borderRadius: "10px" }}>
                Не выдан
              </MenuItem>
              <MenuItem value={"2"} sx={{ borderRadius: "10px" }}>
                Выдан третьему лицу
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: "150px", marginBottom: "20px", width: "250px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
            <span className={styles.selectTitle}>Статус участника</span>
            <button
              className={styles.resetSelectedItem}
              type="button"
              onClick={() => dispatch(props.setParticipantStatus(""))}
            >
              Сбросить
            </button>
          </div>
          <FormControl fullWidth>
            {props.participantStatus ? null : (
              <InputLabel
                id="demo-simple-select-label"
                sx={inputLabel}
                shrink={false}
              >
                Выберите статус
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.participantStatus}
              name="age"
              onChange={(event) =>
                dispatch(props.setParticipantStatus(event.target.value))
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "12px",
                    padding: "0 10px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  },
                },
              }}
              sx={selectCategory}
            >
              <MenuItem
                value={"0"}
                sx={{ borderRadius: "10px" }}
                className={styles.resetMuiDefaultStyle}
              >
                Не верифицирован
              </MenuItem>
              <MenuItem value={"1"} sx={{ borderRadius: "10px" }}>
                Верифицирован
              </MenuItem>
              <MenuItem value={"2"} sx={{ borderRadius: "10px" }}>
                Отклонён
              </MenuItem>
              <MenuItem value={"3"} sx={{ borderRadius: "10px" }}>
                На рассмотрении
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <div className={styles.countriesAutoComplete}>
          <div className={styles.countriesAutoCompleteTop}>
            <span>Страна</span>
            <button
              type="button"
              className={styles.resetSelectedItem}
              onClick={() => {
                dispatch(props.setCountry(null));
                dispatch(props.setCountryIndex(null));
              }}
            >
              Сбросить
            </button>
          </div>
          <div>
            <Autocomplete
              disablePortal
              value={props.country}
              options={modifiedCountries}
              onChange={(e: any, newValue: any) => {
                dispatch(props.setCountry(newValue));
                dispatch(props.setCountryIndex(newValue?.index));
              }}
              sx={participantsListAutoComplete}
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Выберите страну" />
              )}
            />
          </div>
        </div>
        <Box sx={{ minWidth: "150px", width: "250px", margin: "auto 0" }}>
          <Button
            startIcon={<CloseFilter />}
            variant="contained"
            sx={showFilterButton}
            onClick={resetAllFilters}
          >
            Сбросить все фильтры
          </Button>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Filters;
