import { Box, Button, Pagination, Stack } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { PERSONAL_CABINET_PATH, VOUCHERS_PROMOCODES_PATH } from "pages/paths";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { getSubVouchersCreateThunk } from "../../../model/thunks/getSubVouchersCreateThunk.ts";
import { useTranslation } from "react-i18next";
import { SubVoucherEditModal } from "shared/ui/adminPanel/subVoucherEditModal/SubVoucherEditModal";
const SubVoucher = ({
  isAdmin,
  marathonName,
  voucherName,
  corporateClientName,
}) => {
  const { corporateClientId, subVoucherId } = useParams();
  const { t } = useTranslation();
  const [activeStatus, setActiveStatus] = useState();
  const subVouchers = useSelector((state) => state.personalCabinet.subVouchers);
  const subVouchersLoading = useSelector(
    (state) => state.personalCabinet.subVouchersLoading
  );
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 6,
  });
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "№",
        size: 70,
        Cell: (value) => <>{value.row.index + 1}</>,
        enableSorting: false,
      },
      {
        accessorKey: "startKitIssueType",
        header: t("corporateClient.startKitIssueType"),
        size: 150,
        Cell: (value) => {
          switch (value.renderedCellValue) {
            case "Individual":
              return t("corporateClient.individual");
            case "Сorporate":
              return t("corporateClient.corporate");
            default:
              break;
          }
          return <Box>{value.renderedCellValue}</Box>;
        },
      },
      {
        accessorKey: "voucherName",
        header: t("corporateClient.subvoucher"),
        size: 200,
        Cell: (value) => {
          return <Box>{value.renderedCellValue}</Box>;
        },
      },
      {
        accessorKey: "totalPromocodes",
        header: t("corporateClient.slots"),
        size: 70,
        Cell: (value) => {
          const subvoucher = value.row.original;
          return (
            <Box>
              {subvoucher.usedPromocodes}/{subvoucher.totalPromocodes}
            </Box>
          );
        },
      },
      {
        accessorKey: "expirationDate",
        header: t("corporateClient.active_until"),
        size: 100,
        Cell: (value) => dayjs(value.renderedCellValue).format("DD.MM.YYYY"),
      },
      {
        accessorKey: "isActive",
        header: t("corporateClient.status"),
        size: 100,
        Cell: (value) => (
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              color: value.renderedCellValue ? "#237657" : "#FA0E00",
              textTransform: "capitalize",
              borderRadius: "4px",
              background: value.renderedCellValue ? "#E8FFE0" : "#FFECEB",
              fontFamily: "Raleway",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {value.renderedCellValue
              ? t("corporateClient.active")
              : t("corporateClient.un_active")}
          </Button>
        ),
      },
      {
        accessorKey: "action",
        header: "",
        size: 100,
        Cell: (value) => {
          const clickedSubVoucherId = value.row.original.id
          const voucherName = value.row.original.voucherName
          const isActive = value.row.original.isActive
          setActiveStatus(isActive)
          return (
            <div onClick={handleClick}>
              <SubVoucherEditModal
                pageSubVoucherId={subVoucherId}
                corporateClientId={corporateClientId}
                clickedSubVoucherId={clickedSubVoucherId}
                name={voucherName}
                isActive={isActive}
              />
            </div>
          );
        },
      },
    ],
  );

  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (corporateClientId) {
      dispatch(
        getSubVouchersCreateThunk({
          voucherId: subVoucherId,
          corporateClientId,
          pagination,
        })
      );
    }
  }, [corporateClientId, dispatch, pagination, subVoucherId, t, activeStatus]);

  const handleOnPageChange = (event, page) =>
    setPagination({ ...pagination, page });
  const table = useMaterialReactTable({
    columns,
    data: subVouchers?.query || [],
    enableTopToolbar: false,
    enableColumnActions: false,
    manualPagination: true,
    initialState: { density: "comfortable" },
    onPaginationChange: setPagination,
    enablePagination: false,
    state: { pagination, isLoading: subVouchersLoading },
    muiTableHeadRowProps: () => ({
      style: {
        background: "#ECF2F7",
        borderRadius: "20px",
        border: "none",
        boxShadow: "none",
      },
    }),
    muiTablePaperProps: {
      style: {
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: "none ",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        height: "10px",
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: 600,
        fontFamily: "Raleway",
        border: "none",
        "& .MuiTableSortLabel-icon": {
          color: "black !important",
          opacity: 1,
        },
      },
    },
    muiBottomToolbarProps: {
      style: {
        border: "none",
        boxShadow: "none ",
      },
    },
    muiTableBodyCellProps: {
      style: {
        fontSize: "12px",
        fontVariantNumeric: "lining-nums proportional-nums",
        textOverflow: "ellipsis",
        fontFeatureSettings: '"liga" off',
        lineHeight: "20px",
        fontWeight: 500,
        fontFamily: "Raleway",
        color: "#3E444A",
        border: "none",
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const subVoucher = row.original;
        if (!subVoucher.id) {
          console.error('No ID available for subVoucher');
          return;
        }
        !isAdmin
          ? navigate(
            `/${PERSONAL_CABINET_PATH}/${VOUCHERS_PROMOCODES_PATH}/${subVoucher.id}`
          )
          : navigate(`/admin_panel/vouchers/promocodes/${subVoucher.id}`);
      },
      sx: {
        //stripe the rows, make odd rows a darker color
        cursor: "pointer",
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#F5FAFF",
        },
      },
    }),
    muiPaginationProps: {},
  });
  return (
    <div
      style={{ width: "100%", flex: "1", maxWidth: "1280px", margin: "0 auto" }}
    >
      <span
        style={{ color: "#0090D1", whiteSpace: "pre", cursor: "pointer" }}
        onClick={() => nav(-1)}
      >
        {t("corporateClient.vouchers")} &gt;{" "}
      </span>
      <span style={{ whiteSpace: "pre" }}> {corporateClientName}</span>
      <div style={{ margin: "20px 0" }}>
        {" "}
        {marathonName} {marathonName ? "|" : ""} {voucherName}
      </div>
      <Box sx={{ display: "flex", gap: "16px" }}>
        <Box className="container-personalCabinet" sx={{ maxWidth: "300px" }}>
          <h2
            className="title-personalCabinet"
            style={{ marginBottom: "28px" }}
          >
            {t("corporateClient.distances")}
          </h2>
          <Box></Box>
          <Box></Box>
        </Box>
        <div className={"container-personalCabinet"}>
          <h2
            className="title-personalCabinet"
            style={{ marginBottom: "28px" }}
          >
            {t("corporateClient.subvouchers")}
          </h2>
          <Box style={{ overflowX: "auto", flex: 1 }}>
            <Box></Box>
            <MaterialReactTable table={table || []} />
          </Box>
        </div>
      </Box>
      {subVouchers?.query.length === 0 ? null : (
        <Stack
          spacing={2}
          sx={{
            margin: "30px auto 20px auto",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(subVouchers?.count / pagination.pageSize) || 1}
            page={pagination.page}
            onChange={handleOnPageChange}
            color="primary"
            size="medium"
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      )}
    </div>
  );
};

export default SubVoucher;
