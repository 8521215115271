import { Box } from "@mui/material";
import dayjs from "dayjs";

import ButtonWithProgress from "shared/ui/ButtonWIthProgress/ButtonWithProgress";
import { CreateDistanceForm } from "../Distances/CreateDistanceForm";
import { ICreateMarathon } from "features/createMarathon/types";
import { LineTitle } from "shared/ui/LineTitle/LineTitle";
import { UiDatetField } from "shared/ui/UiDatePicker/UiDateField";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import style from "./MarathonContent.module.scss";
import { useAppSelector } from "shared/lib/hooks/hooks";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const MarathonSettings = () => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<ICreateMarathon>();
  const createMarathonRequestLoading = useAppSelector(
    (state) => state.marathon.createMarathonRequestLoading
  );
  const data = watch();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <LineTitle title="Прием заявок" />
      <Box
        sx={{
          mt: "16px",
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          gap: "15px",
        }}
      >
        <UiDatetField
          placeholder="дд.мм.гг"
          error={errors.startDateAcceptingApplications}
          minDate={dayjs()}
          maxDate={data.dateEvent}
          control={control}
          name="startDateAcceptingApplications"
        />
        <UiDatetField
          placeholder="дд.мм.гг"
          error={errors.endDateAcceptingApplications}
          minDate={
            data.startDateAcceptingApplications?.add(1, "day")
              ? data.startDateAcceptingApplications?.add(1, "day")
              : dayjs()
          }
          control={control}
          maxDate={data.dateEvent}
          name="endDateAcceptingApplications"
        />
      </Box>
      <LineTitle title="Дата печати стартовых номеров участников" />
      <Box sx={{ mt: "16px" }}>
        <UiDatetField
          placeholder="дд.мм.гг"
          // error={errors.participantNumbersPrintDate}
          minDate={dayjs()}
          // maxDate={data.participantNumbersPrintDate}
          control={control}
          name="participantNumbersPrintDate"
        />
      </Box>
      <LineTitle title="Страховка" />
      <Box sx={{ mt: "16px" }}>
        <UiTextField
          error={errors.insurancePrice?.message}
          inputProps={{ ...register("insurancePrice") }}
          label="Стоимость страховки"
          type="number"
        />
      </Box>
      <LineTitle title="Дистанции" />
      <Box sx={{ mt: "16px" }}>
        <CreateDistanceForm />
      </Box>
      <Box>
        <div className={style.box_bottom}>
          <div>
            <ButtonWithProgress
              variant="contained"
              className={style.btn_next}
              disabled={createMarathonRequestLoading}
              loading={createMarathonRequestLoading}
              sx={{
                fontFamily: "Raleway",
                textTransform: "capitalize",
                position: "relative",
                "&:hover": { backgroundColor: "#0090D1" },
              }}
              type="submit"
            >
              Создать
            </ButtonWithProgress>
          </div>
        </div>
      </Box>
    </Box>
  );
};
