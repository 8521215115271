import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from '../api/typedApi';

export const getCorporateIssueByMarathonIdAndClientIdSearch = createAsyncThunk(
  'admin/getCorporateIssueByMarathonIdAndClientIdSearch', async (participantInfo: any, { rejectWithValue }) => {
    try {
      const response = await typedAdminAPI.getCorporateIssueByMarathonIdAndClientIdSearch(participantInfo);
      return response.data;
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
