import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Divider from "@mui/material/Divider";
import {marathonCreatedModalButton, marathonCreatedModalTitle} from "entities/admin/lib/MUIStyles/MUIStyles";
import marathonCreatedIcon from 'assets/images/register/emailConfirmed.svg';
import styles from './ImportExcelFile.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 350,
    sm: 450,
    md: 450,
    lg: 450,
  },
  bgcolor: '#B0EEB7',
  pt: '60px',
  borderRadius: '16px',
  border: 'none',
  outline: 'none',
};

type Props = {
  isExcelFileModalOpen: boolean,
  closeExcelFileModal: () => void,
}

const ImportExcelFileModal: React.FC<Props> = (props): JSX.Element => {
  const {isExcelFileModalOpen, closeExcelFileModal} = props;

  return (
    <div>
      <Modal
        open={isExcelFileModalOpen}
        onClose={closeExcelFileModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.innerBox}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={marathonCreatedModalTitle}
            >
              Excel файл был успешно импортирован
            </Typography>
            <Divider/>
            <img src={marathonCreatedIcon} alt="Excel файл был успешно импортирован" style={{
              position: 'absolute',
              top: '-5px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}/>
            <Button
              variant="contained"
              onClick={closeExcelFileModal}
              sx={marathonCreatedModalButton}>
              Закрыть
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ImportExcelFileModal;
