import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalCabinetAPI } from '../api/index';

export const putFileCreateThunk = createAsyncThunk(
    "personalCabinet/uploadFile",
    async (filesData, { rejectedWithValue }) => {
        try {
            const { data } = await personalCabinetAPI.putFiles(filesData)
            return data;
        } catch (error) {
            return rejectedWithValue(error.response.data);
        }
    }
)