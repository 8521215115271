import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { personalCabinetAPI } from "../api/index";

interface PostApplicationPwdData {
  distanceId: number | undefined;
}

export const postApplicationPwdCreateThunk = createAsyncThunk(
  "personalCabinet/postApplicationPwd",
  async (data: PostApplicationPwdData, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.postApplicationPwd(data);
      window.location.href = res.data;
      return res.data;
    } catch (error: any) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title);
    }
  }
);