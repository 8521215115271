import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from '../api/typedApi';

export const getUserPromocodes = createAsyncThunk(
  'user/getUserPromocodes', async (
    userId: number, { rejectWithValue }
  ) => {
  try {
    const response = await typedAdminAPI.getUserPromocodes(userId);
    return response.data;
  } catch (error: any) {
    return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
  }
}
)
